import React from "react";
import { HighlightedText, advertedPoolSize } from "@userbrain/website-ui";

import Layout from "../../layouts/layout";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderCompare } from "../../components/HeaderCompare";
import { HtmlContent } from "../../components/HtmlContent";
import { Testimonials } from "../../components/Testimonials";
import {
  testimonialThomas,
  testimonialJoshua,
  testimonialPaul,
} from "../../helpers";
import * as helperStyles from "../helpers.module.css";
import * as styles from "./compare.module.css";

import imageDashboardSessions from "../../images/compareDashboardSessions.jpg";
import { AutoLink } from "../../components/helpers/AutoLink";
import { CompareLastParagraph } from "../../components/helpers/CompareLastParagraph";

const ContentPage = () => (
  <Layout
    title={"Userfeel vs. Userbrain"}
    metaDescription={
      "Debating whether to choose Userfeel or Userbrain for your user testing needs? We're here to make that decision easier for you by comparing Userfeel vs. Userbrain in a side-by-side comparison."
    }
  >
    <article>
      <HeaderCompare
        title={
          <>
            <span className={helperStyles.h1Serif}>Userfeel vs.</span> Userbrain
          </>
        }
        content={
          "Choosing between Userfeel and Userbrain for your user testing needs? Our side-by-side comparison of Userfeel vs. Userbrain outlines 8 key differences to help you decide the platform that aligns best with your business:"
        }
        imageSrc={imageDashboardSessions}
        imageAlt={
          "Userbrain dashboard showing a list of remote user testing videos"
        }
      />
      <div className={helperStyles.container}>
        <HtmlContent className={styles.htmlContent}>
          <h2>1. Pay-as-you-go vs. subscription flexibility</h2>
          <p>
            Userfeel provides a pay-as-you-go model, enabling you to purchase
            individual tests without a subscription. Conversely, Userbrain
            offers both pay-as-you-go and{" "}
            <AutoLink to="/pricing">subscription plans</AutoLink>, presenting
            you with greater flexibility to select a plan aligning with your
            business needs.
          </p>

          <h2>2. Userbrain's focus on English, Spanish, and German testing</h2>
          <p>
            Userbrain provides high-quality user testing in English, catering to
            businesses primarily targeting English-speaking users. Headquartered
            in Austria (the heart of Europe!) Userbrain also has a large pool of{" "}
            <a href="https://www.userbrain.com/blog/spanish-user-testing-now-available">
              Spanish-speaking
            </a>{" "}
            and German-speaking testers, ensuring reliable and valuable
            insights.
          </p>

          <h2>3. Transparent pricing for both platforms</h2>
          <p>
            Userfeel and Userbrain offer transparent pricing, making costs
            clear. No hidden fees – you know exactly what you're paying for.
          </p>

          <h2>4. Comprehensive features for all Userbrain users</h2>
          <p>
            Userbrain provides all its features, including access to a global
            pool of testers and the ability to specify testers by device type,
            language, age, gender, and region, regardless of your subscription.
            Userfeel provides a variety of features too, but Userbrain's
            all-inclusive approach guarantees you have everything for
            unmoderated remote user tests.
          </p>

          <h2>5. Userbrain's AI Insights Feature</h2>
          <p>
            Userbrain’s AI assistant enhances your testing process by
            discovering UX insights and generating time-stamped notes. This not
            only saves time but also includes all{" "}
            <a href="https://www.userbrain.com/blog/automated-insights-streamline-user-test-analysis-with-ai-assistance">
              AI insights
            </a>{" "}
            and notes in the report automatically, enriching your analysis.
          </p>

          <h2>6. Userbrain's efficient tester pool</h2>
          <p>
            While Userfeel has a large tester pool, Userbrain focuses on
            quality, maintaining a pool of{" "}
            <AutoLink to="/features/tester-pool/">
              over {advertedPoolSize} qualified testers
            </AutoLink>{" "}
            with an average rating of 4.4 out of 5. This ensures valuable
            insights from testers adept at providing actionable feedback.
          </p>

          <h2>7. Simple, efficient user testing with Userbrain</h2>
          <p>
            Userbrain focuses on simplicity with a clean interface for optimal
            user testing. While Userfeel offers a user-friendly platform,
            Userbrain's streamlined experience is appealing to those seeking
            efficiency.
          </p>

          <h2>8. Automation for regular user testing</h2>
          <p>
            Userbrain emphasizes frequent user testing, offering automated
            testing options with its subscription plans. This feature encourages
            user testing as a habit, without the need to schedule tests
            manually. Userfeel offers a powerful platform but doesn't emphasize
            automated testing as Userbrain does.
          </p>

          <h2>9. Userbrain's commitment to tester availability</h2>
          <p>
            Userbrain guarantees tester availability. If specific targeting
            criteria cannot be met, Userbrain won't offer those options,
            preventing time wastage waiting for unavailable testers. While
            Userfeel offers a large tester pool, Userbrain's transparency about
            tester availability stands out.
          </p>

          <p>
            Both Userfeel and Userbrain have their strengths and unique
            features. With its focus on English language testing, comprehensive
            features for all users, efficient tester pool, AI insights, and a
            commitment to simplicity and automation, Userbrain is an attractive
            choice for businesses seeking a streamlined and efficient user
            testing experience.
          </p>

          <CompareLastParagraph />
        </HtmlContent>
      </div>
      <div className={helperStyles.container}>
        <Testimonials
          testimonials={[testimonialJoshua, testimonialThomas, testimonialPaul]}
        />
        <GetStartedBox
          className={helperStyles.spacingLg}
          title={
            <>
              Ready for your first{" "}
              <HighlightedText cursor>user test?</HighlightedText>
            </>
          }
          textBottom={
            "Get set up in minutes. Your first test is ready in a few hours."
          }
        />
      </div>
    </article>
  </Layout>
);

export default ContentPage;
